<template>
  <div class="preparations">
    <div v-if="loading" class="loading-container">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </div>
    <div v-else ref="printable">
      <div class="preparations-title mt-4 px-4 mb-6">
        <div class="text-h4 text-center mb-4">Preparo do agendamento</div>
        <div class="font-weight-bold">
          Paciente: {{ patient }} <span class="ml-4">Data: {{ date }}</span>
          <span class="ml-4">Hora: {{ hour }}</span>
        </div>

        <div class="mt-3 d-flex flex-wrap">
          <div class="font-weight-bold mr-4 pt-1">Exames:</div>
          <div
            v-for="(procedure, index) in procedures"
            class="exam-label mr-4 mb-2"
            :key="index"
          >
            {{ procedure }}
          </div>
        </div>
      </div>
      <div class="preparations-body">
        <div>
          <v-btn
            color="primary"
            title="Imprimir"
            icon
            class="mb-8"
            @click="print"
          >
            <v-icon>mdi-printer</v-icon>
          </v-btn>
        </div>
        <div ref="rtf"></div>
      </div>
    </div>
  </div>
</template>
<script type="text/javascript">
import Api from '@/resources/NajaSocialApi'
import { EMFJS, RTFJS, WMFJS } from 'rtf.js'
RTFJS.loggingEnabled(false)
WMFJS.loggingEnabled(false)
EMFJS.loggingEnabled(false)
export default {
  name: 'Prepatations',
  data: () => ({
    loading: false,
    saving: false,
    date: null,
    patient: null,
    procedures: [],
    hour: null,
  }),

  mounted() {
    this.fetch()
  },

  methods: {
    print() {
      const prtHtml = this.$refs.printable.innerHTML.replace(
        /<button[\s\S]*?<\/button>/g,
        '</br></br>'
      )

      // Get all stylesheets HTML
      const stylesHtml = `
          <style type="text/css" scoped>
            body {
              font-family: 'Avenir', Helvetica, Arial, sans-serif;
              font-size: 14px;
              color: rgba(0, 0, 0, 0.87);
            }

            .container {
              max-width: 600px;
              margin: 0 auto;
              padding: 1em;
            }

            .text-center {
              text-align: center!important;
            }

            .text-h4 {
              font-size: 2.125rem!important;
              line-height: 2.5rem;
              letter-spacing: .0073529412em!important;
            }

            .px-4 {
              padding-right: 16px!important;
              padding-left: 16px!important;
            }

            .mt-4 {
              margin-top: 16px!important;
            }
            .mt-3 {
              margin-top: 12px!important;
            }
            .mr-4 {
              margin-right: 16px!important;
            }
            .ml-4 {
              margin-left: 16px!important;
            }
            .mb-4 {
              margin-bottom: 16px!important;
            }
            .mr-2 {
              margin-right: 8px!important;
            }
            .pt-1 {
              padding-top: 4px!important;
            }
            .d-flex {
              display: flex!important;
            }
            .flex-wrap {
              flex-wrap: wrap!important;
            }
            .font-weight-bold {
              font-weight: 700!important;
            }
            .preparations-body {
              padding-right: 2rem;
              padding-left: 2rem;
            }
          </style>
        `

      const WinPrint = window.open(
        '',
        '',
        'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0'
      )
      WinPrint.document.write(`<!DOCTYPE html>
        <html>
          <head></head>
          <body>
            ${stylesHtml}
            ${prtHtml}
          </body>
        </html>`)

      WinPrint.document.close()
      WinPrint.focus()
      WinPrint.print()
      WinPrint.close()
    },
    _getHour(procedimentos) {
      const hour = procedimentos
        .map(x => x.procedimento.hora)
        .sort((a, b) => a - b)[0]
      return hour ? this.$moment(hour, 'HHmm').format('HH:mm') : ''
    },
    _parse(data) {
      this.patient = data.paciente.nome
      this.hour = this._getHour(data.procedimentos)
      this.procedures = data.procedimentos.map(x => x.procedimento.nome)
      this.mountRTF(data.texto)
      this.date = this.$moment(data.data_agendamento).format('DD/MM/YYYY')
    },
    async fetch() {
      try {
        this.loading = true
        const token = this.$route.params.token

        const response = await Api.core.preparations.fetch(token)
        this._parse(response.data)
      } catch (err) {
        if (err.response) {
          if (err?.response?.status != 500) {
            this.$toast.show(err.response.data.errors.join(','), 'error')
          } else {
            this.$toast.show('Erro ao buscar dados', 'error')
          }
        } else {
          throw err
        }
      } finally {
        this.loading = false
      }
    },
    stringToArrayBuffer(string) {
      const buffer = new ArrayBuffer(string.length)
      const bufferView = new Uint8Array(buffer)
      for (let i = 0; i < string.length; i++) {
        bufferView[i] = string.charCodeAt(i)
      }
      return buffer
    },

    mountRTF(rtfText) {
      const doc = new RTFJS.Document(this.stringToArrayBuffer(rtfText))
      doc
        .render()
        .then(htmlElements => {
          htmlElements.forEach(element => {
            this.$refs.rtf.appendChild(element)
          })
        })
        .catch(error => console.error(error))
    },
  },
}
</script>
<style scoped lang="scss">
.preparations {
  flex-direction: column;
  padding: 1rem;
  width: 90%;
  max-width: 748px;

  .exam-label {
    font-weight: bold;
    padding: 5px 10px;
    border-radius: 5px;
    background-color: #f4f4f8;
  }

  .loading-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 3rem;
  }

  .text-h6 {
    // font-size: 1.8rem !important;
    font-size: 2.125rem !important;
  }

  .text-subtitle-2 {
    // font-size: 1rem !important;
    font-size: 1.125rem !important;
  }

  .section-title {
    color: var(--v-primary);
  }
}

.preparations-title {
  // height: 100px;
  margin-bottom: 1rem;
}

.preparations-body {
  background: #f4f4f8;
  border-radius: 1.875rem;
  padding: 2rem;
  // height: 100%;
  // overflow-y: auto;
}
// .preparations-content {
//   background-color: white;
//   padding: 1rem;
//   overflow-y: auto;
//   width: 100%;
//   height: 100%;
//   border-radius: 1.5rem 1.5rem 0px 0px;
// }
</style>
<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/_variables';
@media #{map-get($display-breakpoints, 'sm-and-down')} {
  // .root-container {
  //   padding: 25px 17px !important;
  //   margin-top: 50px;
  // }
}
</style>
